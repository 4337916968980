<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <!-- <li class="breadcrumb-item active">Locations</li> -->
            </ol>
            <router-link :to="{
              name: 'monthly-plans',
            }">
              <button type="button" class="btn btn-sm btn-blue waves-effect waves-light mb-2">
                <i class="mdi mdi-arrow-left"></i>
                Back
              </button>
            </router-link>
          </div>
          <h4 class="page-title" v-if="!loading">
            {{ monthlyPlanLists[0].name }} Lists
          </h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <place-holder v-if="loading"></place-holder>

            <div class="table-responsive">
              <table class="table table-centered" v-show="!loading">
                <thead class="table-light table-bordered">
                  <tr>
                    <th>No.</th>
                    <th>Employee Type</th>
                    <th style="min-width: 180px !important">Module Group</th>
                    <th style="min-width: 180px !important">Module</th>
                    <th style="min-width: 120px !important">From Date</th>
                    <th style="min-width: 120px !important">End Date</th>
                    <th>Start Time</th>
                    <th style="min-width: 120px !important">
                      Enrolled Expired Date
                    </th>
                    <th>Training Hours</th>
                    <th style="min-width: 120px !important">Training Type</th>
                    <th style="min-width: 150px !important">Location/Link</th>
                    <th style="min-width: 150px !important">Trainer</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(plan, index) in monthlyPlanLists" :key="index">
                    <td>{{ ++index }}</td>
                    <td>
                      <span v-if="plan.employee_type_id == 1">Program</span>
                      <span v-if="plan.employee_type_id == 2">Normal</span>
                    </td>
                    <td>
                      {{ plan.module_group.name }}
                    </td>
                    <td>
                      {{ plan.module.name }}
                    </td>
                    <td>
                      {{ plan.from_date }}
                    </td>
                    <td>
                      {{ plan.to_date }}
                    </td>
                    <td>
                      {{ plan.start_time }}
                    </td>
                    <td>
                      {{ plan.expired_date }}
                    </td>
                    <td>
                      {{ plan.module.duration }}
                    </td>
                    <td>
                      {{ plan.training_type }}
                    </td>
                    <td>
                      {{ plan.location }}
                    </td>
                    <td>
                      <span v-for="(trainer, index) in plan.trainers" :key="index">{{ trainer.user.name }}
                        <span v-if="index + 1 < plan.trainers.length">, </span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      monthlyPlanLists: [],
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getAllMonthlyPlansLists() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .post(`${this.baseUrl}admin/v1/get-monthly-plans`, {
          budget_year_id: this.$route.params.id,
          month: this.$route.params.month,
        })
        .then((response) => {
          this.monthlyPlanLists = response.data.data;

          this.loading = false;
          this.$Progress.finish();
        });
    },
  },
  created() {
    this.getAllMonthlyPlansLists();
  },
};
</script>

<style></style>